<template>
  <div>
    <Header></Header>
    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table">
              <div class="head">
                <h3>Plans</h3>
                <div class="d-flex"></div>
              </div>
              <div class="content">
                <div class="spinner-wrap" v-if="isLoading">
                  <b-spinner variant="light" class="mr-2" medium></b-spinner>
                  <div class="text">Loading, please wait...</div>
                </div>
                <div class="row" v-if="!isLoading">
                  <div class="col-lg-4" v-for="(plan, i) in plans" :key="i">
                    <div class="plan-item">
                      <strong>{{ plan.name }}</strong>
                      <div class="shipments">
                        Max shipments
                        <span>{{ plan.max_shipments }}</span>
                      </div>
                      <div class="pricing">{{ plan.price | toCurrency }}</div>
                      <ul>
                        <li>Fast delivery</li>
                        <li>More shipments</li>
                        <li>Basic plan</li>
                        <li>Lorem ipsum dolor</li>
                        <li>Sit amet</li>
                      </ul>
                      <button @click="checkPlan(plan.id)">Buy Plan</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-modal-bg" v-if="showModal"></div>
    <div class="c-modal modal-s" v-if="showModal">
      <div class="alert-modal">
        <div class="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="#ff9000"
            class="bi bi-exclamation-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
            />
          </svg>
        </div>
        <strong>{{ errorData.message }}</strong>
        <div class="d-flex">
          <button class="btn text-white mr-2" @click="showModal = false">
            No, thanks
          </button>
          <button class="btn btn-primary" @click="buyPlan()">
            Yes, i want to continue
          </button>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";
import { uuid } from "vue-uuid";
export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
  },
  data() {
    return {
      errors: [],
      plans: [],
      showModal: false,
      isLoading: false,
      isTableLoading: false,
      nonce: "",
      errorData: {},
      selectedPlan: {},
    };
  },
  mounted() {
    this.getPlans();
    this.nonce = uuid.v4();
  },
  methods: {
    getPlans() {
      this.isLoading = true;

      axios
        .get(`plans`)
        .then((res) => {
          console.log(res.data);
          this.plans = res.data.records;
          this.isLoading = false;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    checkPlan(id) {
      const planData = {
        plan_id: id,
        confirm_conflict: false,
        check_conflict: true,
        nonce: this.nonce,
      };

      this.selectedPlan = planData;

      axios
        .post(`plans/buy`, planData)
        .then((res) => {
          console.log("asad", res);
          if (res.data.code == "NO_CONFLICT") {
            this.showModal = true;
          }
        })
        .catch((err) => {
          this.errorData = err.response.data;
          if (err.response.data.code == "PLAN_CONFLICT_DISCOUNT") {
            this.showModal = true;
          }
          if (err.response.data.code == "PLAN_CONFLICT_REFUND") {
            this.showModal = true;
          }
        });
    },

    buyPlan() {
      this.selectedPlan.confirm_conflict = true;
      this.selectedPlan.check_conflict = false;

      axios
        .post(`plans/buy`, this.selectedPlan)
        .then((res) => {
          console.log("asad", res.data);
          if (res.data.code == "PLAN_PURCHASED") {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "top-right",
            });
            this.showModal = false;
            this.selectedPlan = {};
            this.$router.push({ path: "/profile" });
          } else if (err.response.data.code == "INSUFFICIENT_FUNDS") {
            this.showModal = false;
            this.$toast.open({
              message: "Insufficient funds",
              type: "error",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.errorData = err.response.data;
          this.errors.push(err);
          if (err.response.data.code == "INSUFFICIENT_FUNDS") {
            this.showModal = false;
            this.$toast.open({
              message: "Insufficient funds",
              type: "error",
              position: "top-right",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
